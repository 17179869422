.footer-wrapper {
  background-color: #e2e2e2;
  /* z-index: -1; */
  position: relative;
}

.footer h5,
.footer a {
  color: #736e6d;
}

.footer li {
  padding: 0.55rem;
}

.border-right-footer {
  border-right: #a8a8a8 1px solid;
}

.social-icons {
  padding: 3rem;
}

.social-icons a {
  margin: 0.5rem;
}
.social-icons .social-icon-link svg {
  background-color: #ffffffa8;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  padding: 0.5rem;
  border: 2px solid #fff;
  transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
}

.social-icons .social-icon-link svg:hover {
  background-color: #fff;
}

.footer-copyright {
  background-color: #736e6d;
}

.footer-copyright small {
  color: #fff;
}

.hide {
  display: none;
}

.title-bryant {
  font-family: 'Bryant-Bold';
}

.text-bryant {
  font-family: 'Bryant-regular';
}

.footer-section {
  max-width: 300px;
}
/* Responsive Styles */

/* Tablet */
@media only screen and (max-width: 1024px) {
  .contact-col.border-right-footer {
    border: 0 !important;
  }

  .brands-col {
    padding-top: 1.5rem;
  }
}

@media only screen and (min-width: 415px) and (max-width: 1024px) {
  .brands-col {
    border-top: 1px solid #a8a8a8 !important;
  }
}
