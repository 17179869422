.sticky-container {
  width: 100%;
  margin: auto;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: -1;
  width: 100%;
}

.sticky-content {
  position: relative;
  background-color: #fff;
  width: 100%;
}

@media (min-width: 415px) and (max-width: 1024px) {
  .sticky-content {
    margin-top: calc(4rem - 1px);
  }

  .sticky-header {
    top: 4rem;
  }
}

@media only screen and (max-width: 414px) {
  .sticky-header {
    top: 70px;
  }
}
