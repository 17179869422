@import url('https://maxcdn.icons8.com/fonts/line-awesome/1.1/css/line-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap');
@font-face {
  font-family: 'Bryant-regular';
  src: local('Bryant'),
    url('./assets/fonts/Bryant/BryantPro-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Bryant-Bold';
  src: local('Bryant'),
    url('./assets/fonts/Bryant/BryantPro-Bold.otf') format('opentype');
}

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-gray {
  color: #d1d1d1 !important;
}

.text-primary {
  color: #e51b26 !important;
}

.bg-primary {
  background-color: #e51b26 !important;
  color: #fff;
}

.btn-danger {
  background-color: #e51b26 !important;
  border-color: #e51b26 !important;
  color: #fff;
}

/* .btn-primary:hover {
  transition: all ease-in 0.4s;
  background-color: #f5f5f5 !important;
  border-color: #e51b26 !important;
  color: #e51b26;
} */

.ff-segoe {
  font-family: 'Segoe UI';
}

.spinner-show {
  height: 100vh;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  padding: 5rem;
  margin-top: 5rem;
}

.special-container {
  max-width: 1880px !important;
}

a:hover {
  color: #e51b26;
  text-decoration: none;
}

@media only screen and (max-width: 414px) {
  .phone-mobile {
    display: inline-block;
  }
  .phone-lg {
    display: none;
  }
}

@media only screen and (min-width: 415px) {
  .phone-lg {
    display: inline-block;
  }
  .phone-mobile {
    display: none;
  }
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

.scroll-to-top {
  background-color: #e51b26;
  color: #fff;
  right: 40px;
  bottom: 100px;
  position: fixed;
  z-index: 2;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  transition: opacity 1s ease-in-out;
  box-shadow: 0 9px 25px 0 rgb(132 128 177 / 28%);
  border: none;
  outline: none;
  padding: 0.2rem;
}

.scroll-to-top .la {
  font-size: 22px;
  padding: 0.5rem 0.2rem;
}

#widget-global-re9x491ephp * {
  z-index: 0;
}

.react-swipeable-view-container {
  z-index: 1;
  position: relative;
}

.icon-status-refered-wrapper {
  padding: 0.25rem 0.7rem;
}

.icon-up-swipe {
  display: none;
}

@media (min-width: 415px) and (max-width: 1024px) {
  .icon-status-refered {
    display: none;
  }

  .icon-status-refered-wrapper {
    font-size: 12px;
  }

  .client-referred-wrapper {
    width: 100%;
    overflow: scroll;
  }
}

@media screen and (max-width: 1024px) {
  .icon-up-swipe {
    display: block;
    position: absolute;
    top: 0;
    left: 48%;
    background-color: #e51b26;
    padding: 0.15rem 0.4rem;
    color: #fff;
    border-radius: 0 0 15px 15px;
    text-align: center;
  }
}

@media screen and (max-width: 414px) {
  .icon-up-swipe {
    left: 45%;
  }
  .react-swipeable-view-container {
    margin-top: 6rem;
  }
}

.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #e51b26;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 480px) {
  .sticky-content {
    top: 4rem;
  }
}
