.affiliate-bussines-text {
  resize: none;
}

.affiliate-bussines {
  background-color: rgba(245, 245, 245, 0.7) !important;
}

.creditline-select {
  width: 100%;
}

.creditline-title {
  font: normal normal bold 20px/27px Segoe UI;
  letter-spacing: 0px;
  color: #736e6d;
}
