.bg-login {
  background: #736e6d94 !important;
}

.login-header {
  border-bottom: none !important;
  text-align: center;
  font: normal normal bold 21px/28px 'Segoe UI';
  letter-spacing: 0px;
  color: #736e6d;
}

.login-input-label {
  font: normal normal normal 18px/24px 'Segoe UI';
  letter-spacing: 0px;
  color: #736e6d;
}

.login-eye-btn {
  background-color: transparent !important;
  border-left: none !important;
}

.input-password {
  border-right: none !important;
}
