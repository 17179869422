.overlayImage {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  padding: 0;
}

.title-banner {
  position: absolute;
  top: 75%;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 414px) {
  .title-banner {
    top: -25%;
  }
}

@media only screen and (min-width: 415px) and (max-width: 1024px) {
  .title-banner {
    top: 0;
  }
}
