.header-sidebar {
  margin-top: 5rem;
  width: 100%;
}

.navbar {
  padding: 0 !important;
}
.btn-login {
  border-radius: 0 !important;
}

.icon-login-btn {
  padding: 0.75rem 0.65rem;
}

#mainNav .nav-menu .nav-link::before {
  content: '';
  display: block;
  width: 0px;
  height: 2px;
  background-color: #e51b26;
  transition: 0.2s;
  position: relative;
  bottom: -39px;
}

#mainNav .nav-menu .nav-link:hover::before,
#mainNav .nav-menu .nav-link.active::before {
  width: 100%;
}

#mainNav .nav-menu .nav-link.active {
  color: #e51b26;
}

.credex-logo {
  width: 120px;
}

.zu-logo {
  width: 65px;
}

#mobile-menu .content-sidebar {
  margin-top: 5rem;
  text-align: left;
}

#mobile-menu {
  z-index: 3 !important;
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  transform: translateX(0);
  width: 60%;
}

#mobile-menu .content-sidebar .nav-link {
  color: #736e6d;
  padding-top: 0.5rem;
  line-height: 2;
}

.mobile-active {
  border-left: solid 3px #e51b26;
  color: #e51b26 !important;
  font-weight: bold;
}

/* Responsive Styles */

/* Movil */

@media only screen and (max-width: 414px) {
  #mobile-menu {
    width: 75%;
  }

  .login-mobile-buttom {
    margin-bottom: 15rem;
  }
}

@media (min-width: 414px) and (max-width: 1024px) {
  #mobile-menu {
    width: 75%;
  }
}

/* Tablet */
@media only screen and (max-width: 1024px) {
  .btn-login {
    display: none !important;
  }

  #mainNav {
    padding: 1rem !important;
  }

  #mainNav .nav-menu .nav-link::before {
    display: none;
  }

  .nav-menu {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) {
  .btn-login-mobile {
    display: none !important;
  }
}
